import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/slices/auth-slice";
import { AppState } from "../redux/store";

export const useAutoLogout = (timeoutDuration = 15 * 60 * 1000) => {
    const dispatch = useDispatch();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const user = useSelector((state: AppState) => state.auth.user);

    // Function to log out the user
    const logoutUser = () => {
        window.alert("You have been logged out due to inactivity.");
        localStorage.removeItem("token");
        dispatch(logout())
        // refresh the page
        window.location.reload();
    };

    // Function to reset the logout timer
    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            logoutUser(); // Trigger logout after the timeout
        }, timeoutDuration);
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        // Set up event listeners for user activity
        const events = ["click", "mousemove", "keypress", "scroll", "touchstart"];

        const eventHandler = () => {
            resetTimeout();
        };

        events.forEach((event) => window.addEventListener(event, eventHandler));

        // Start the initial timeout
        resetTimeout();

        // Cleanup the event listeners and timeout when the component unmounts
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            events.forEach((event) => window.removeEventListener(event, eventHandler));
        };
    }, [timeoutDuration]);

    return null;
};
