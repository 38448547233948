import React, { useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useSalesfrontCollectionsQuery } from '../../../features/api/products-api'
import { useNavigate } from 'react-router-dom';

export default function SearchCategory() {
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();
    const { data } = useSalesfrontCollectionsQuery();


    const handleSelectChange = (selectedOption: { id: string; title: string }) => {
        setSelectedOption(selectedOption.title);
    };

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault(); // Prevent the default form submission
        if (!search.trim()) {
            return; // If the search field is empty, prevent the search
        }
        navigate(`/shop?search=${encodeURIComponent(search)}&productType=${encodeURIComponent(selectedOption)}`);

        setSearch('');
    };
    return (
        <>
            <div className='w-full border border-solid border-gray-200 rounded-full px-3 py-1'>
                <form onSubmit={handleSearch} className='flex items-center justify-between'>
                    <div className='flex items-center 2xl:gap-6 xl:gap-6 md:gap-6 lg:gap-6 gap-3'>
                        <div className='relative 2xl:w-60 xl:w-60 md:w-60 lg:w-60 w-52'>
                            <CategorySelect options={data?.collections?.slice(0, 10) || []} onChange={handleSelectChange} />
                        </div>
                        <input type='text' placeholder='Search anything...' required className='w-full text-sm outline-none focus:border-none' value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <button type='submit' className='2xl:size-8 xl:size-8 md:size-8 lg:size-8 size-8 text-white rounded-full bg-primary flex justify-center items-center'>
                        <FiSearch className='2xl:text-base xl:text-base md:text-base lg:text-base text-xs' />
                    </button>
                </form>
            </div>

            {/* ====== language */}
        </>
    )
}

interface Option {
    id: string;
    title: string;
}

interface CustomSelectProps {
    options: Option[];
    defaultValue?: Option;
    onChange: (selectedOption: Option) => void;
}

export const CategorySelect: React.FC<CustomSelectProps> = ({ options, defaultValue, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(defaultValue);
    const divRef = useRef<HTMLUListElement | null>(null);
    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChange(option);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left w-full">
            <button
                type="button"
                className="px-3 py-2 bg-gray-100 text-sm flex justify-between w-full rounded-full gap-3 items-center"
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <span className="block truncate">
                    {selectedOption ? selectedOption.title : "All Categories"}
                </span>
                <MdOutlineKeyboardArrowDown size={16} />
            </button>

            {isOpen && (
                <ul ref={divRef} className="absolute z-[100] mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {options?.map((option) => (
                        <li
                            key={option.id}
                            className={`cursor-pointer select-none relative py-2 pl-3 pr-9 ${selectedOption?.id === option.id ? 'text-primary' : 'text-gray-900 font-normal'
                                }`}
                            onClick={() => handleOptionClick(option)}
                        >
                            <span className="block 2xl:truncate xl:truncate md:truncate lg:truncate">
                                {option.title}
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

