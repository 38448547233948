import React, { Fragment, Suspense, useEffect, useState } from "react";
import ProductInfo from "./product-info";
import { useParams } from "react-router-dom";
import { useToast } from "../../components/shared/ToastContext";
import { useShopifyProductQuery } from "../../features/api/products-api";
import useUtils from "../../hooks/useUtils";
import { FaRegHeart } from "react-icons/fa6";
import { CartItem } from "../../types/type";
import { getShapedProduct } from "../../utils/utils";

interface ProductGalleryProps {
  images: any[];
  selectedVariant: any;
  setSelectedVariant: any;
  _id: any
}

const ProductGallery: React.FC<ProductGalleryProps> = ({ images, setSelectedVariant, selectedVariant, _id }) => {


  const [mainImage, setMainImage] = useState(images?.[0]);
  const [selectedImage, setSelectedImage] = useState(0);
  const { id } = useParams<{ id: string }>();
  const { showToast } = useToast();
  const { handleWishlist, checkExistInWishlist } = useUtils();
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { isLoading, data: productDetails } = useShopifyProductQuery(id);

  useEffect(() => {
    setAddedToWishlist(checkExistInWishlist(id || ""));
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const handleIncrement = () => {
    if (productDetails?.quantity === quantity) {
      return showToast("Out of stock", "error", "Out of Stock");;
    }
    setQuantity((prev) => prev + 1);
  };

  const handleDecrement = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity((prev) => prev - 1);
  };

  const imagesGallery = [...images];
  useEffect(() => {
    setMainImage(images?.[0]);
  }, [images]);

  useEffect(() => {
    if (selectedVariant) {
      let variantImage = images.find(
        (item: any) => item?.id === selectedVariant?.image_id
      );
      setMainImage(variantImage || "");
    }
  }, [selectedVariant, images]);

  const imagesSide = imagesGallery?.length > 5 ? imagesGallery?.slice(0, 5) : imagesGallery;

  const handleAddToWishlist = () => {
    const product = getShapedProduct(productDetails);
    handleWishlist(product, (res: boolean) => {
      setAddedToWishlist(res);
    });
  };

  return (
    <Fragment>
      <div className='flex 2xl:flex-row xl:flex-row md:flex-row pt-8 lg:flex-row flex-col 2xl:items-center xl:items-center gap-6'>
        <div className='2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2'>
          <div className='flex 2xl:flex-row xl:flex-row md:flex-row pt-8 lg:flex-row flex-col-reverse gap-4'>
            <div className='2xl:w-36 xl:w-36 h-auto'>
              <div className='flex 2xl:flex-col xl:flex-col md:flex-col 2xl:pt-0 xl:pt-0 md:pt-0 lg:pt-0 pt-2 lg:flex-col flex-row 2xl:gap-y-5 xl:gap-y-5 md:gap-y-5 lg:gap-y-5 gap-x-4'>
                {imagesSide?.map((item: any, index) => (
                  <button type="button" key={index} onClick={() => { setMainImage(item); setSelectedImage(index) }} className={`2xl:size-24 xl:size-24 flex justify-center items-center md:size-24 lg:size-24 size-14 outline-none border border-solid ${index === selectedImage ? "border-black" : "border-gray-300"} rounded-xl`}>
                    <Suspense fallback={<div className="w-20 h-20 bg-gray-200 rounded-xl"></div>}>
                      <img src={item?.src} alt="" loading="lazy" className="2xl:size-20 xl:size-20 md:size-20 lg:size-20 size-10 rounded-xl" />
                    </Suspense>
                  </button>
                ))}
              </div>
            </div>
            <div className='w-full relative'>
              <button onClick={handleAddToWishlist} className={`size-10 absolute right-4 2xl:top-4 xl:top-4 md:top-4 lg:top-4 top-4 border-2 border-solid border-gray-200 ${addedToWishlist ? "bg-primary" : "bg-white"} flex justify-center items-center rounded-full cursor-pointer`}>
                <FaRegHeart />
              </button>
              <Suspense fallback={<div className="w-full !min-h-[560px] bg-gray-200 rounded-xl"></div>}>
                <img loading="lazy" src={mainImage?.src || images?.[0]?.src} className="w-full md:h-auto h-[300px] object-cover lg:max-h-[600px] border border-solid rounded-xl p-2" alt={"Product details"} />
              </Suspense>
            </div>
          </div>
        </div>
        {/* ====== product details ===== */}
        <div className='2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2'>
          <ProductInfo
            product={productDetails}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            brand={productDetails?.brand || productDetails?.productType}
            quantity={quantity}
            isLoading={isLoading}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            // handleAddToWishlist={productAddToWishlist}
            // handleAddToCart={handleAddToCart}
            reviews={productDetails?.reviews}
            stock={productDetails?.quantity}
            title={productDetails?.title}
            // description={productDetails?.description}
            ratings={productDetails?.averageRatings}
            price={productDetails?.price || productDetails?.variants?.[0]?.price}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductGallery;
