import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import 'swiper/css';

export default function BannerSlider() {
    const banners = ["https://res.cloudinary.com/dgnve8m5k/image/upload/v1725857751/assets/banners/damndeal-earn-with-spending.png", "https://res.cloudinary.com/dgnve8m5k/image/upload/v1725857752/assets/banners/magic-pools-join-damndeal.png"]
    return (
        <>
            <main className="max-w-screen-2xl mx-auto 2xl:mt-8 xl:mt-8 md:mt-8 lg:mt-8 mt-3 relative 2xl:px-0 xl:px-16 px-3">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay]}
                    speed={2000}
                    loop={true}
                >
                    {
                        banners.map((banner, index) => (
                            <SwiperSlide key={index}>
                                <div className="w-full relative overflow-hidden">
                                    <img src={banner} alt="Damndeal Banner Image" className="w-full h-auto object-cover aspect-[16/9] md:aspect-auto rounded-2xl" />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </main>
        </>
    )
}
