import { useState } from "react"
import { useSelector } from "react-redux"
import { AppState } from "../../../../redux/store"
import useUtils from "../../../../hooks/useUtils"
import { IoIosHeart, IoIosStar } from "react-icons/io"
import { CiHeart } from "react-icons/ci"
import { TbShoppingBagMinus, TbShoppingBagPlus } from "react-icons/tb"
import { Link } from "react-router-dom"
import { getCurrencySymbol, getShapedProduct } from "../../../../utils/utils"

const ProductCard = ({ item, id }: any) => {
    const { handleCart, handleWishlist, checkExistInCart, checkExistInWishlist } = useUtils()
    const [addedToCart, setAddedToCart] = useState(checkExistInCart(item?.id))
    const [addedToWishlist, setAddedToWishlist] = useState(checkExistInWishlist(item?.id))
    const currency = useSelector((state: AppState) => state.auth.currency)

    return (
        <>
            <div className='w-full bg-white group cursor-pointer p-3 border border-solid border-gray-300 relative rounded-3xl'>
                <div className='absolute right-6 top-6 flex flex-col gap-y-3 opacity-0 group-hover:opacity-100 transition-all ease-linear duration-500'>
                    <button type="button" className='size-8 bg-primary rounded-full flex justify-center items-center' onClick={() => {
                        handleWishlist(getShapedProduct(item), (res: boolean) => {
                            setAddedToWishlist(res)
                        })
                    }}>
                        {
                            addedToWishlist ?
                                <IoIosHeart /> :
                                <CiHeart />
                        }
                    </button>
                    <button type="button" className={`size-8 bg-primary rounded-full flex justify-center items-center`} onClick={() => {
                        handleCart(getShapedProduct(item), (res: boolean) => {
                            setAddedToCart(res)
                        })
                    }}>
                        {addedToCart ?
                            <TbShoppingBagMinus /> :
                            <TbShoppingBagPlus />
                        }
                    </button>
                </div>
                <Link to={`/product-details/${id}`}><img src={item?.image} alt="" className="w-full h-56 mb-4 object-cover rounded-xl" /></Link>
                <Link to={`/product-details/${id}`} className='text-sm hover:text-primary capitalize'>{item?.title?.length > 50 ? item?.title?.slice(0, 50) + "..." : item?.title}</Link>
                <div className='flex justify-between items-center pt-2'>
                    <div className='flex items-center gap-2'>
                        <p className='font-medium text-lg'>
                            {getCurrencySymbol(currency) + item?.price}
                        </p>
                        <span className='text-xs font-thin text-gray-500 line-through'>
                            {getCurrencySymbol(currency) + (Number(item?.price) * (item?.discount || 1.2)).toFixed(2)}
                        </span>
                    </div>
                    {item.rating ? <div className='bg-primary px-3 py-1 w-fit rounded-full text-sm flex items-center gap-2'>
                        5.0
                        <IoIosStar />
                    </div>: <p className='text-xs text-gray-500'>No rating</p>}
                </div>
            </div>
        </>
    )
}


export default ProductCard;