import React, { useState } from 'react'
import { IoIosStar } from 'react-icons/io';
// import Swiper core and required modules

import { Swiper, SwiperSlide } from 'swiper/react';
import { getCurrencySymbol, getShapedProduct } from '../../../../utils/utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/store';
import useUtils from '../../../../hooks/useUtils';
import { CartItem } from '../../../../types/type';
import { Link } from 'react-router-dom';

type TopSellingProductCardProps = {
    products: any
}
export default function TopSellingProductCard({
    products
}: TopSellingProductCardProps) {

    return (
        <>
            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 1.2,
                    },
                    639: {
                        slidesPerView: 1.2,
                    },
                    865: {
                        slidesPerView: 4
                    },
                    1000: {
                        slidesPerView: 4
                    },
                    1500: {
                        slidesPerView: 4
                    },
                    1700: {
                        slidesPerView: 4
                    }
                }}
            >
                {
                    Array(4).fill(0)?.map((item: number, index: number) => (
                        <SwiperSlide key={index}>
                            <CardsProduct item={item} products={products} index={index} />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </>
    )
}

// ======= Cards Product ====
export const CardsProduct = ({ item, index, products }: any) => {
    return (
        <div className='w-full bg-[#F4EBD9] p-3 rounded-lg'>
            <div className='flex flex-col gap-6 w-full'>
                {products?.slice(
                    index * 3,
                    (index + 1) * 3
                )?.map((item: any, index: number) => (
                    <SingleProductCard item={item} key={index} />
                ))}
            </div>
        </div>
    )
}

const SingleProductCard = ({ item }: any) => {
    const currency = useSelector((state: AppState) => state.auth.currency)

    const { handleCart, handleWishlist, checkExistInCart, checkExistInWishlist } = useUtils()
    const [addedToCart, setAddedToCart] = useState(checkExistInCart(item?.id))

    return (
        <div className='flex flex-row w-full gap-4' key={item?._id}>
            <Link to={`/product-details/${item?.id}`} className='w-[68px] h-[70px]'>
                <img src={item?.image} className='size-full rounded-xl' alt="" />
            </Link>
            <div className='flex flex-col gap-2 w-full'>
                <h4>
                    {item.title?.length > 20 ? item.title?.slice(0, 20) + "..." : item.title}
                </h4>
                <div className='flex justify-between w-full items-center'>
                    <div className='flex items-center gap-2'>
                        <p className='font-medium'>
                            {getCurrencySymbol(currency) + "" + item.price}
                        </p>
                    </div>
                    {item.rating ? <div className='bg-primary px-3 py-1 w-fit rounded-full text-sm flex items-center gap-2'>
                        5.0
                        <IoIosStar />
                    </div> : <p className='text-xs text-gray-500'>No rating</p>}
                </div>
                <button type='button' onClick={() => {
                    handleCart(getShapedProduct(item), (res: boolean) => {
                        setAddedToCart(res)
                    })
                }} className='px-4 w-fit py-1 bg-black text-sm text-white rounded-full'>{addedToCart ? "Remove from Cart" : "Add to cart"}</button>
            </div>
        </div>
    )
}