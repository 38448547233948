import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../utils/utils';
import commonApi from '../../redux/common-api';


interface User {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    phone: string;
    email: string;
    state: string;
}

export const apiSlices = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/auth/',
        headers: {
            'Content-Type': 'application/json',
        },
        prepareHeaders(headers, api) {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('Authorization', `${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["user"],

    endpoints: (builder) => ({
        // Define your endpoints here
        getDate: builder.query<{ message: string }, void>({
            query: () => "update",
        }),
        getJsonToken: builder.query<{ token: string }, void>({
            query: () => ({
                url: 'refresh_token',
                method: 'POST',
            })
        }),
        updateData: builder.mutation<User, any>({
            query: (data) => ({
                url: 'update',
                method: 'PATCH',
                body: JSON.stringify(data),
            }),
            invalidatesTags: ["user"],
        }),
        updatePassword: builder.mutation<User, any>({
            query: (data) => ({
                url: 'update-password',
                method: 'PUT',
                body: JSON.stringify(data),
            }),
            invalidatesTags: ["user"],
        }),
        forgotPassword: builder.mutation<any, any>({
            query: (data) => ({
                url: 'forgot',
                method: "POST",
                body: JSON.stringify(data),
            }),
        }),
        resetPassword: builder.mutation<{ msg: string }, { password: string; activation_token: string }>({
            query: (data) => ({
                url: 'reset',
                method: 'POST',
                body: JSON.stringify(data),
            })
        }),

        login: builder.mutation<{ token: string }, { email: string; password: string }>({
            query: (data) => ({
                url: 'login',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),

        register: builder.mutation<any, any>({
            query: (data) => ({
                url: 'register',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),

        getProfile: builder.query<any, void>({
            query: () => "user_data",
            providesTags: ["user"],
        }),

        getUserByID: builder.query({
            query: (token) => ({
                url: `get_user`,
                method: 'GET',
                headers: {
                    'x-auth-token': token,
                }
            }),
        }),

        activateEmail: builder.mutation<any, any>({
            query: (data) => ({
                url: `/activation`,
                method: 'POST',
                body: JSON.stringify(data),
            }),
            invalidatesTags: ["user"],
        }),

        verifyEmail: builder.mutation<any, any>({
            query: (data) => ({
                url: `/verify`,
                method: 'POST',
                body: JSON.stringify(data),
            }),
            invalidatesTags: ["user"],
        }),
    }),
});

export const eEclubs = createApi({
    reducerPath: 'eBooks',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/product/',
        headers: {
            'Content-Type': 'application/json',
        },
        prepareHeaders(headers, api) {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('Authorization', `${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["product", "shopify", "magic-club", "wallet", "ebook", "customer-orders", "e-club"],
    endpoints: (builder) => ({
        getMagicClubs: builder.query<any, void>({
            query: () => `user/magic-club`,
            providesTags: ["magic-club"],
        }),

        getEClubs: builder.query({
            query: () => "/ebook/e-clubs",
            providesTags: ["e-club"],
        }),
        getUserWallet: builder.query({
            query: () => `user/wallet`,
            providesTags: ["wallet"],
        }),
        getEBookData: builder.query({
            query: (user) => `/ebook/${user?._id}`,
            providesTags: ["ebook"],
        })
    }),
});


export const couponsApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        createCoupon: builder.mutation({
            query: (data: any) => ({
                url: `ad`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: [{ type: "ad" }],
        }),
        updateCoupon: builder.mutation({
            query: (data: any) => ({
                url: `ad/${data.id}`,
                method: "PUT",
                body: data.body,
            }),
            invalidatesTags: ["ad"],
        }),
        getCoupon: builder.query({
            query: (id: any) => `ad/${id}`,
            providesTags: ["ad"],
        }),
        getMyCoupons: builder.query({
            query: () => `ad/me`,
            providesTags: ["ad"],
        }),
        getAllCoupons: builder.query<any, void>({
            query: (query: any) => ({
                url: `ad?limit=${query?.limit || 20}`,
                method: "GET",
            }),
            providesTags: ["ad"],
        }),
        redeemCoupon: builder.mutation({
            query: (data: any) => ({
                url: `ad/redeem-coupon`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["ad", "ebook"],
        }),
        deleteCoupon: builder.mutation({
            query: (id: any) => ({
                url: `ad/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ad"],
        }),
    }),
});

export const { useGetDateQuery, useUpdateDataMutation, useGetJsonTokenQuery, useForgotPasswordMutation, useResetPasswordMutation, useLoginMutation, useRegisterMutation,
    useGetProfileQuery, useGetUserByIDQuery, useActivateEmailMutation, useVerifyEmailMutation, useUpdatePasswordMutation } = apiSlices;

export const { useGetMagicClubsQuery, useGetEClubsQuery, useGetEBookDataQuery } = eEclubs;

export const { useCreateCouponMutation, useUpdateCouponMutation, useGetCouponQuery, useGetAllCouponsQuery, useRedeemCouponMutation, useGetMyCouponsQuery, useDeleteCouponMutation } = couponsApi;