import React from 'react'
import Dialog from '../forms/dialog/dialog'
import { useDispatch } from 'react-redux'
import { logout } from '../../../../features/slices/auth-slice';

const LogoutConfirmModal = ({
    open,
    setOpen
}: {
    open: boolean,
    setOpen: (option: boolean) => void
}) => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.removeItem("token");
        dispatch(logout());
        window.location.assign("/");
    }
    
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Dialog title='Logout' open={open} onClose={handleClose} onConfirm={handleLogout}>
            <p className='text-gray-400'>Are you sure you want to logout</p>
        </Dialog>
    )
}

export default LogoutConfirmModal