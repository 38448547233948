import { useForm } from "react-hook-form";
import { useUpdatePasswordMutation } from "../../features/slices/api-slices";
import { useToast } from "../../components/shared/ToastContext";
import { useState } from "react";
import { FiEye } from "react-icons/fi";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { passwordSchema as passwordValidationSchema } from "../../features/schemas";
import { useDispatch } from "react-redux";
import { logout } from "../../features/slices/auth-slice";
import { useNavigate } from "react-router-dom";

// Zod schema for password validation
const passwordSchema = z.object({
    password: z.string().min(1, { message: "Old Password is required" }),
    newpass: passwordValidationSchema,
    repass: z.string().min(1, { message: "Re-enter Password is required" }),
}).refine((data) => data.newpass === data.repass, {
    message: "Passwords do not match",
    path: ["repass"], // Add error message to repass field
});

export default function ChangePasswordPage() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: zodResolver(passwordSchema),
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [update, { isLoading, isSuccess, error }] = useUpdatePasswordMutation();
    const { showToast } = useToast();
    const [isVisible, setIsVisible] = useState(false);
    const [passwordVisible, setpasswordVisible] = useState(false);
    const [reEnterPassword, setreEnterPassword] = useState(false);

    const onSubmit = async (data: any) => {
        if (data.password === data.newpass) {
            showToast("Old password and new password cannot be the same", "error", "Password Error");
            return;
        }
        try {
            await update({
                password: data.password,
                new_password: data.newpass,
            }).unwrap();
            showToast("Password successfully changed!", "success");
            reset();
            setTimeout(() => {
                localStorage.removeItem("token");
                dispatch(logout());
                window.location.assign("/");
            }, 1000)
        } catch (err) {
            showToast((err as any)?.data?.msg, "error", "We're sorry, but we couldn't reset your password.");
        }
    };

    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };

    return (
        <>
            <div className='w-full h-fit p-8 pt-5 lg:rounded-3xl bg-white'>
                <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Change Password</h2>
                {isSuccess && <p>Password successfully changed!</p>}

                <div className='2xl:flex xl:flex md:flex lg:flex justify-between block pt-12'>
                    <div className='2xl:w-2/3 xl:w-2/3 space-y-8 md:w-2/3 lg:w-2/3 w-full'>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.password ? "text-red-500" : "text-zinc-800"}`}>Old Password *</label>
                                <input type={isVisible ? "text" : "password"} placeholder='•••••••••••••' className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.password ? "border-red-500" : "border-gray-200"}`}
                                    {...register("password")} aria-invalid={errors.password ? "true" : "false"} />
                                {errors.password && <p role="alert" className="text-sm text-red-500 mt-2">{errors.password.message?.toString()}</p>}
                                <div className='absolute top-4 right-4 cursor-pointer'>
                                    {isVisible ? <FiEye onClick={toggleVisibility} className="text-2xl text-gray-500" /> : <img loading="lazy" src="/assets/images/svg/eye.svg" onClick={toggleVisibility} alt="" />}
                                </div>
                            </div>

                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.newpass ? "text-red-500" : "text-zinc-800"}`}>New Password *</label>
                                <input type={passwordVisible ? "text" : "password"} {...register("newpass")} placeholder='•••••••••••••' className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.newpass ? "border-red-500" : "border-gray-200"}`} />
                                {errors.newpass && <p role="alert" className="text-sm text-red-500 mt-2">{errors.newpass.message?.toString()}</p>}
                                <div className="absolute top-4 right-4 cursor-pointer">
                                    {passwordVisible ? <FiEye onClick={() => setpasswordVisible((prev) => !prev)} className="text-2xl text-gray-500" /> : <img loading="lazy" src="/assets/images/svg/eye.svg" onClick={() => setpasswordVisible((prev) => !prev)} alt="" />}
                                </div>
                            </div>

                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.repass ? "text-red-500" : "text-zinc-800"}`}>Re-enter Password *</label>
                                <input type={reEnterPassword ? "text" : "password"} {...register("repass")} placeholder='•••••••••••••' className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.repass ? "border-red-500" : "border-gray-200"}`} />
                                {errors.repass && <p role="alert" className="text-sm text-red-500 mt-2">{errors?.repass?.message?.toString()}</p>}
                                <div className="absolute top-4 right-4 cursor-pointer">
                                    {reEnterPassword ? <FiEye onClick={() => setreEnterPassword((prev) => !prev)} className="text-2xl text-gray-500" /> : <img loading="lazy" src="/assets/images/svg/eye.svg" onClick={() => setreEnterPassword((prev) => !prev)} alt="" />}
                                </div>
                            </div>

                            <div className="flex justify-start gap-8 2xl:pt-16 xl:pt-16 md:pt-16 lg:pt-16 pt-5">
                                <button type="submit" disabled={isLoading} className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                    {isLoading ? "Loading..." : "Save"}
                                </button>
                                <button type="button" className="w-fit h-[46px] 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex"
                                    onClick={() => {
                                        navigate('/user-panel/profile')
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                    <img loading="lazy" src="/assets/images/image-2.png" className='h-64 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-8' alt="" />
                </div>

            </div>
        </>
    );
}
